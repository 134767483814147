
#page-landing {

  ul {
    text-align: center;
    color: #4587E6;

    a {
      color: #4587E6;
    }

    li {
      display     : inline-block;
      cursor      : pointer;
      width       : 10%;
      margin      : 0 2.5%;
      font-weight : bold;
      font-family : 'Hind';
      font-size   : 1.2em;
      position    : relative;
      transition  : color 0.2s;
      height      : 7%;
      min-width: 150px;

      &.client-type {
        img.icon {
          height: 8em;
        }
      }

      .clipper {
        background-color : #71AAEE;
        background-color : #C8E5FD;
        width            : 120%;
        height           : 110%;
        top              : -5%;
        left             : -10%;
        position         : absolute;
        z-index          : -1;
        border-radius    : 4px;
        clip-path        : circle(0% at -5% 105%);
        transition       : clip-path 0.2s ease-out;
      }

      &:hover {
        .clipper {
          clip-path: circle(150% at 100% 100%);
          box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
        }
      }

      &:active .clipper {
        background-color: white;
      }

    }
  }


  .section {
    margin-top: 10em;
    text-align: center;

    .panel {
      display   : inline-block;
      width     : 40%;
      min-width : 300px;
      color     : #4587E6;
      vertical-align : middle;

      &.pop-out {
        img {
          width: 90%;
        }
      }

      &.promo {
        margin: 2em 1%;
        padding: 2em;
        text-align: left;

        h2 {
          font-weight: 900;
        }

      }

    }
  }

}

