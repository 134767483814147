
//***
// Color palette

// https://www.materialpalette.com/blue/amber
$color_neutral_bkg    : #f5f5f5;
$color_accent         : #ffc107;
$color_divider        : #bdbdbd;
$color_icons          : #ffffff;
$color_primary        : #2196f3;
$color_primary-dark   : #1976d2;
$color_primary-light  : #bbdefb;
$color_text           : #ffffff;
$color_text-primary   : #212121;
$color_text-secondary : #757575;

.primary {
  background-color: $color_primary;
  color: white;

  &.dark {
    background-color: $color_primary-dark;
  }

  &.light {
    background-color: $color_primary-light;
  }

}

.accent {

}


//***
// vertical spacers

hr {
  margin: 2em 0 0 0;
  border: 1px solid orange;
  width: 100%;
}

vertical-spacer {
  display : block;
  height  : 0px;

  // This is effectively the default size.
  margin-top: 4rem;
  &[size="default"] { }

  &[size="small" ] { margin-top: 2rem; }
  &[size="medium"] { margin-top: 4rem; }
  &[size="large" ] { margin-top: 6rem; }
}

.no-vmargin {
  margin: 0 auto;
}

.no-hmargin {
  margin: auto 0;
}

.no-margin {
  margin: 0;
}

horizontal-spacer {
  display : inline-block;
  height  : 0px;
  width   : 0px;

  // This is effectively the default size.
  margin-left: 4rem;
  &[size="default"] { }

  &[size="small" ] { margin-left: 2rem; }
  &[size="medium"] { margin-left: 4rem; }
  &[size="large" ] { margin-left: 6rem; }
}

//***
// Links
a {
  display         : inline-block;
  margin          : auto 0.2em;
  text-decoration : none;
  color           : #aa3333;

  &:hover {
    color: green;
  }

  // If it has a name tag, it's a page anchor.  Don't style it specially.
  &[name] {
    display : block;
    color   : black;
  }
}

//***
// Headers

h1, h2, h3, h4, h5, h6 {
  font-weight : normal;
  margin      : 1rem 0 0.25rem 0;
  display: block;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.28em;
}

h5 {
  font-size: 1.025em;
}

h6 {
  font-size: 0.82em;
}

//***
// Step cards

step-list {
  display          : block;
  margin           : auto 4em;

  step-list-title {
    font-size: 2rem;
  }

  step-card {
    margin: 2em auto;
    display: block;
    height: 300px;

    padding-bottom: 2.5rem;

    &[card-align="right"] {
      step-number,
      step-title {
        margin-right: 0;
        margin-left: auto;
      }
    }

    step-number {
      display : block;
      height  : 30px;
      color   : $color_primary;
      width   : 50%;
      font-size: 1.3rem;
    }

    step-title {
      display   : block;
      height    : 50px;
      width     : 50%;
      font-size : 1.75rem;
    }

    step-image,
    step-copy {
      display        : inline-block;
      width          : 50%;
      height         : 220px;
      font-size      : 1rem;
      vertical-align : top;
      text-align     : justify;
      color: $color_text-secondary;
      line-height: 1.4rem;
      font-weight: 400;
    }

    step-image {
      height     : 300px;
      text-align : center;
      margin-top : -80px;

      img {
        width     : 100%;
        height    : 100%;
        margin    : auto auto;
        max-width : 50%;
      }
    }
  }
}

//***
// Content blocks

content-block {
  display: block;
  width: 100%;
  margin: auto;
  
  &.centered {
    text-align: center;
  }

  img {
    height: 250px;
  }

  > * {
    display: inline-block;
  }

  //***
  // These items ought NOT to be inline-block inside a content-block
  // element.
  > h1, > h2, > h3, > h4, > h5, > h6 {
    display: block;
  }

}

.fill-10  { width: 10%    ; }
.fill-20  { width: 20%    ; }
.fill-25  { width: 25%    ; }
.fill-30  { width: 30%    ; }
.fill-33  { width: 33.33% ; }
.fill-40  { width: 40%    ; }
.fill-50  { width: 50%    ; }
.fill-60  { width: 60%    ; }
.fill-66  { width: 66.66% ; }
.fill-70  { width: 70%    ; }
.fill-75  { width: 75%    ; }
.fill-80  { width: 80%    ; }
.fill-90  { width: 90%    ; }
.fill-100 { width: 100%   ; }

p {
  font-size: 1em;

  &.spaced-compact {
    line-height: 1em;
  }

  &.spaced-normal {
    line-height: 1.2em;
  }

  &.spaced-wide {
    line-height: 1.6em;
  }
}
