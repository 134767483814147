//**********
// Material-UI font support.

// material-ui font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
// material-ui icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
//**********

// Used for the COVID-19 update banner
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');



//**********
// Custom theme stuff.  These are globals that apply everywhere.
@import "./styles/theme.scss";
//**********


body {
  background-color : white;
  margin : auto;
  width  : 100%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

